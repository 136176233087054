import * as Sentry from '@sentry/react'
import { PostHogProvider } from 'posthog-js/react'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './scaffold.css'
import { AuthProvider, useAuth } from './auth'

import './index.css'
// import 'intro.js/introjs.css'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4d4ccc012635e517019ecffe578a424e@o4505134687977472.ingest.sentry.io/4506230476177408',
    integrations: [
      // Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    beforeSend(event) {
      // Check if the error matches the one you want to suppress
      const suppressedErrors = [
        'Missing Refresh Token',
        'Unknown or invalid refresh token',
      ]

      if (
        suppressedErrors.some((error) =>
          event.message?.includes(error)
        ) ||
        event.exception?.values?.some((exception) =>
          suppressedErrors.some((error) => exception.value.includes(error))
        )
      ) {
        // Return null to drop the event
        return null
      }

      // Otherwise, send the event to Sentry
      return event
    },
    // Performance Monitoring
    // tracesSampleRate: 1.0,
    // Session Replay
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 1.0,
  })
}

const options = {
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
}

const App = () => (
  <PostHogProvider apiKey={process.env.PUBLIC_POSTHOG_KEY} options={options}>
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider>
          <RedwoodApolloProvider useAuth={useAuth}>
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  </PostHogProvider>
)

export default App
